import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { Observable, map } from 'rxjs';
import { DownloadedFile } from 'src/app/common/models/downloaded-file.model';
import GenericResponseModel from 'src/app/common/models/generic-response.model';
import PagedResult from 'src/app/common/models/paged-result.model';
import { SortDirection } from 'src/app/common/models/sort-direction.enum';
import { BaseService } from 'src/app/common/services/base-http.service';
import ListArticleModel from 'src/modules/shared/models/article-overview.model';
import ArticleSearchResponseModel from 'src/modules/shared/models/article-search-response.model';

import Article from '../types/article.model';
import { DuplicateArticle } from '../types/article.models';
import ArticleCreateResponseModel from '../types/article-create-response.model';
import { CalculatedArticlePrice } from '../types/article-prices.models';
import { ArticleSearchRequestModel } from '../types/article-search.model';
import ArticleUpdateResponseModel from '../types/article-update-response.model';
import CreateArticleModel from '../types/create-article.model';
import { ReplacementArticles } from '../types/replacement-article.models';
import UpdateArticleModel from '../types/update-article.model';

@Injectable({ providedIn: 'root' })
export class ArticleService extends BaseService {
  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  public createArticle(createArticleModel: CreateArticleModel): Observable<GenericResponseModel<ArticleCreateResponseModel>> {
    return this.httpClient.post<GenericResponseModel<ArticleCreateResponseModel>>(`/api/articles`, createArticleModel);
  }

  public deleteArticle(id: number): Observable<GenericResponseModel> {
    return this.httpClient.delete<GenericResponseModel>(`/api/articles/${id}`);
  }

  public downloadArticleExcel(
    supplierNameFrom?: null | string,
    supplierNameTo?: null | string,
    articleCodeFrom?: null | string,
    articleCodeTo?: null | string,
    articleGroupFrom?: null | string,
    articleGroupTo?: null | string,
  ): Observable<DownloadedFile> {
    const params = new URLSearchParams();
    if (articleCodeFrom) {
      params.append('articleCodeFrom', articleCodeFrom);
    }

    if (articleCodeTo) {
      params.append('articleCodeTo', articleCodeTo);
    }

    if (supplierNameFrom) {
      params.append('supplierNameFrom', supplierNameFrom);
    }

    if (supplierNameTo) {
      params.append('supplierNameTo', supplierNameTo);
    }

    if (articleGroupFrom) {
      params.append('articleGroupFrom', articleGroupFrom);
    }

    if (articleGroupTo) {
      params.append('articleGroupTo', articleGroupTo);
    }

    return this.httpClient
      .get(`/api/articles/excel?${params}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(response => {
          const contentDisposition = response.headers.get('Content-Disposition') || '';
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
          const filename = (matches?.length ?? 0) > 1 && matches !== null ? matches[1].replace(/['"]/g, '') : `propositions.pdf`;
          return {
            content: response.body,
            filename: filename,
          } as DownloadedFile;
        }),
      );
  }

  public duplicateArticle(
    articleId: number,
    duplicateArticleModel: DuplicateArticle,
  ): Observable<GenericResponseModel<ArticleCreateResponseModel>> {
    return this.httpClient.post<GenericResponseModel<ArticleCreateResponseModel>>(
      `/api/articles/${articleId}/duplicate`,
      duplicateArticleModel,
    );
  }

  public getArticle(id: number): Observable<GenericResponseModel<Article>> {
    return this.httpClient.get<GenericResponseModel<Article>>(`/api/articles/${id}`);
  }

  public getArticleDefaultPrice(id: number): Observable<GenericResponseModel<CalculatedArticlePrice>> {
    return this.httpClient.get<GenericResponseModel<CalculatedArticlePrice>>(`/api/articles/${id}/calculated`);
  }

  public getArticleReceipt(id: number): Observable<GenericResponseModel<number>> {
    return this.httpClient.get<GenericResponseModel<number>>(`/api/articles/${id}/receipt`);
  }

  public getArticles(
    searchRequestModel: ArticleSearchRequestModel,
  ): Observable<GenericResponseModel<ArticleSearchResponseModel>> {
    return this.httpClient.get<GenericResponseModel<ArticleSearchResponseModel>>('/api/articles/search', {
      params: this.encodeObjectToQueryParams(searchRequestModel),
    });
  }

  public searchArticles(
    pageIndex: number,
    pageSize: number,
    sortField?: string,
    sortDirection?: SortDirection,
    searchTerm?: string,
    isBaseArticle?: boolean,
    articleCategoryId?: number,
    articleGroupId?: number,
    baseArticleId?: number,
    includeBaseArticle?: boolean,
    supplierId?: number,
    priceUpdated?: Moment,
    showPurchasePrice?: boolean,
  ): Observable<PagedResult<ListArticleModel>> {
    const params = new URLSearchParams();
    params.append('page', (pageIndex + 1).toString());
    params.append('pageSize', pageSize.toString());

    if (sortField) {
      params.append('sortField', sortField);
    }

    if (sortDirection) {
      params.append('sortDirection', sortDirection);
    }

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    if (typeof isBaseArticle !== 'undefined') {
      params.append('isBaseArticle', isBaseArticle.toString());
    }

    if (typeof includeBaseArticle !== 'undefined') {
      params.append('includeBaseArticle', includeBaseArticle.toString());
    }

    if (articleCategoryId) {
      params.append('articleCategoryId', articleCategoryId.toString());
    }

    if (articleGroupId) {
      params.append('articleGroupId', articleGroupId.toString());
    }

    if (baseArticleId) {
      params.append('baseArticleId', baseArticleId.toString());
    }

    if (supplierId) {
      params.append('supplierId', supplierId.toString());
    }

    if (priceUpdated) {
      params.append('priceUpdated', moment(priceUpdated).format('L'));
    }

    if (typeof showPurchasePrice !== 'undefined') {
      params.append('showPurchasePrice', showPurchasePrice.toString());
    }

    return this.httpClient.get<GenericResponseModel<PagedResult<ListArticleModel>>>(`/api/articles/search?${params}`).pipe(
      map(result => {
        return {
          data: result.data.data,
          totalCount: result.data.totalCount,
        };
      }),
    );
  }

  public updateArticle(
    id: number,
    updateArticleModel: UpdateArticleModel,
  ): Observable<GenericResponseModel<ArticleUpdateResponseModel>> {
    return this.httpClient.put<GenericResponseModel<ArticleUpdateResponseModel>>(`/api/articles/${id}`, updateArticleModel);
  }

  public updateArticleBaseArticle(
    id: number,
    updateArticleModel: ReplacementArticles,
  ): Observable<GenericResponseModel<ArticleUpdateResponseModel>> {
    return this.httpClient.put<GenericResponseModel<ArticleUpdateResponseModel>>(
      `/api/articles/${id}/replacement-articles`,
      updateArticleModel,
    );
  }
}
