import { CurrencyPipe, NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subject, takeUntil } from 'rxjs';

import { DefaultDataSource } from '../../../../app/common/data-sources/default-data-source';
import ListArticleModel from '../../models/article-overview.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    CurrencyPipe,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  selector: 'ingenix-article-table',
  standalone: true,
  styleUrls: ['./article-table.component.scss'],
  templateUrl: './article-table.component.html',
})
export class ArticleTableComponent implements AfterViewInit {
  private readonly destroy$ = new Subject<void>();
  @Output() public copyArticle = new EventEmitter<number>();

  @Output() public deleteClicked = new EventEmitter<number>();
  @Input()
  public displayedColumns: string[] = [
    'edit',
    'code',
    'description',
    'articleGroupDescription',
    'exclusiveVatPrice',
    'webshopPrice',
    'nettoPurchasePrice',
    'stockQuantity',
    'orderedQuantity',
    'backOrderQuantity',
    'isBaseArticle',
  ];
  @Input() public editStock = false;
  @Output() public emitStockChange = new EventEmitter<{ amount: number; id: number }>();

  @Output() public expandArticleRow = new EventEmitter<ListArticleModel>();

  public expandedRow?: ListArticleModel;
  @Input() public forLabels = false;
  @Input() public forStock = false;
  @Input() public isDialog = false;
  @Input() public isLoading = signal<boolean>(false);
  @Input()
  public isReadOnly: boolean = false;

  @Output() public pageChanged = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator) public paginator!: MatPaginator;

  @Input() public replacementArticles!: ListArticleModel[];

  public replacementArticlesDataSource = new DefaultDataSource<ListArticleModel | null>();

  public replacementDisplayedColumns: string[] = [
    'edit',
    'code',
    'description',
    // 'articleGroupDescription',
    'exclusiveVatPrice',
    'webshopPrice',
    'stockQuantity',
    'orderedQuantity',
    'backOrderQuantity',
  ];

  @Output() public rowClicked = new EventEmitter<ListArticleModel>();

  @Output() selected = new EventEmitter<(number | undefined)[]>();

  @Input()
  public selectedArticleId?: number;
  public selectedArticles: (number | undefined)[] = [];

  @Input() public showCollapse?: boolean = true;

  @Input() showHeader?: boolean = true;
  @Input() public showPurchasePrice = signal<boolean>(false);

  @Input()
  public showUnlink: boolean = false;
  public sort: Sort = {
    active: 'code',
    direction: 'asc',
  };

  @Output() public sortChanged = new EventEmitter<Sort>();
  public tableDataSource = new DefaultDataSource<ListArticleModel | null>();
  @Input() public totalCount?: null | number;

  @Output() public unlinkArticle = new EventEmitter<number>();
  public identifyArticle(_: number, item: ListArticleModel) {
    return item.id;
  }
  public isChecked(id: number) {
    return this.selectedArticles?.includes(id);
  }

  public ngAfterViewInit(): void {
    this.paginator?.page.pipe(takeUntil(this.destroy$)).subscribe(event => {
      this.pageChanged.emit(event);
    });
  }

  public onClickArticle(row: ListArticleModel): void {
    this.rowClicked.emit(row);
  }

  public onCopyArticle(id: number): void {
    this.copyArticle.emit(id);
  }

  public onDeleteArticle(id: number): void {
    this.deleteClicked.emit(id);
  }

  public onEnterPressed(event: KeyboardEvent, row: ListArticleModel) {
    if (event.key !== 'Enter') {
      return;
    }

    let index = this.tableDataSource.data.findIndex(c => c?.id === row.id);

    if (index !== -1 && index < this.tableDataSource.data.length - 1) {
      index++; // Move to the next row

      // Emit stock change event
      // this.emitStockChange.emit({ amount: row.stockQuantity!, id: row.id });

      // Wait a bit to ensure UI updates before focusing the next input
      setTimeout(() => {
        document.querySelector<HTMLInputElement>(`#input${this.tableDataSource.data[index]?.id}`)?.focus();
      }, 50);
    }
  }

  public onExpandArticleRow(row: ListArticleModel): void {
    if (!row.hasChildItems) {
      return;
    }

    if ((this.expandedRow?.id ?? 0) === row.id) {
      this.expandedRow = undefined;
      this.expandArticleRow.emit(undefined);
    } else {
      this.expandedRow = row;
      this.expandArticleRow.emit(row);
    }
  }

  public onFocusField(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      inputElement.select();
    }
  }

  public onSortChanged(event: Sort): void {
    if (!event.direction) event.direction = 'asc';

    this.sortChanged.emit(event);
  }

  public onUnlinkArticle(id: number): void {
    this.unlinkArticle.emit(id);
  }

  public selectAll() {
    if (this.selectedArticles?.length == this.tableDataSource.data?.length) {
      this.selectedArticles = [];
    } else {
      this.selectedArticles = this.tableDataSource.data?.map(c => c?.id);
    }
    this.selected.emit(this.selectedArticles);
  }

  public stockColor(number: number) {
    if (this.forStock) {
      return number == 0 ? 'empty' : number == 1 ? 'final' : 'stock';
    } else {
      return '';
    }
  }

  public updateSelected(id: number) {
    if (this.selectedArticles?.includes(id)) {
      this.selectedArticles = this.selectedArticles.filter(c => c !== id);
    } else {
      this.selectedArticles.push(id);
    }
    this.selected.emit(this.selectedArticles);
  }

  public updateStockQuantity(row: ListArticleModel) {
    const index = this.tableDataSource.data.findIndex(c => c?.id === row.id);

    if (index !== -1) {
      this.emitStockChange.emit({ amount: row.stockQuantity!, id: row.id });
    }
  }

  public get allChecked() {
    return this.selectedArticles?.length === this.tableDataSource.data?.length;
  }

  @Input()
  public set articles(articles: ListArticleModel[] | null[]) {
    this.tableDataSource.data = articles;
  }
}
