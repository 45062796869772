import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Observable, Subject, debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs';

import { ListItemComponent } from '../../../../app/components/list-item/list-item.component';
import ListItem from '../../../shared/models/list-item.model';
import { ListItemsService } from '../../../shared/services/list-items.service';
import { ArticleSearchFilterModel } from '../../types/article-search.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ListItemComponent,
    FormsModule,
    NgClass,
  ],
  selector: 'ingenix-article-table-filter',
  standalone: true,
  styleUrls: ['./article-table-filter.component.scss'],
  templateUrl: './article-table-filter.component.html',
})
export class ArticleTableFilterComponent implements OnInit, OnDestroy {
  private readonly EMISSION_INTERVAL = 500;

  private readonly destroy$ = new Subject<void>();

  @Output() private handleAmountChanged = new EventEmitter<number>();

  @Output() private handleArticleGroupChanged = new EventEmitter<null | number>();
  @Output() private handleIsBaseArticleChanged = new EventEmitter<boolean | null>();
  @Output() private handleReset = new EventEmitter<boolean | null>();
  @Output() private handleSearchTermChanged = new EventEmitter<null | string>();

  @Output() private handleShowPurchasePriceChanged = new EventEmitter<boolean | null>();
  private keypressTimestamps: number[] = [];

  private lastEmissionTime: number = 0;

  private scannedCode: string = '';

  public articleGroups$?: Observable<ListItem[]>;
  @Output() public readonly filter: EventEmitter<ArticleSearchFilterModel> = new EventEmitter<ArticleSearchFilterModel>();
  public isScanned: boolean = false;
  public searchFormGroup = this.fb.group({
    articleGroupId: new FormControl<null | number>(null),
    articleGroupSearchTerm: new FormControl<null | string>(null),
    isBaseArticle: new FormControl<boolean | null>(true),
    searchTerm: new FormControl<null | string>(null),
    showPurchasePrice: new FormControl<boolean | null>(false),
  });
  @Input() public showArticleGroupFilter = false;
  @Input() public showMainArticleFilter = true;
  @Input() public showPurchasePriceFilter = false;
  constructor(
    private readonly fb: NonNullableFormBuilder,
    private articleGroupService: ListItemsService,
  ) {}
  private watchIsBaseArticle(): void {
    this.searchFormGroup.controls.isBaseArticle.valueChanges
      .pipe(debounceTime(2000), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(isBaseArticle => this.handleIsBaseArticleChanged.emit(isBaseArticle));

    this.searchFormGroup.controls.showPurchasePrice.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(showPurchasePrice => this.handleShowPurchasePriceChanged.emit(showPurchasePrice));
  }
  private watchSearchTerm(): void {
    this.searchFormGroup.controls.searchTerm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(searchTerm => this.handleSearchTermChanged.emit(searchTerm));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  public ngOnInit(): void {
    this.watchIsBaseArticle();
    this.watchSearchTerm();
    this.watchArticleGroupSearch();
    this.watchArticleGroup();
  }
  public onClickFilter(): void {
    this.searchFormGroup.updateValueAndValidity({ emitEvent: true, onlySelf: true });
  }
  public onClickReset(): void {
    this.searchFormGroup.patchValue({
      isBaseArticle: true,
      searchTerm: null,
    });

    this.handleReset.emit(true);
  }

  public onKeyPress(): void {
    const currentTimestamp = Date.now();
    const maxKeypressBuffer = 14; // Check the last 14 keypresses
    const timeThreshold = 90; // Maximum speed per keypress in milliseconds

    this.keypressTimestamps.push(currentTimestamp);

    if (this.keypressTimestamps.length > maxKeypressBuffer) {
      this.keypressTimestamps.shift();
    }

    let currentValue = this.searchFormGroup.controls.searchTerm.value || '';
    const timediff = currentTimestamp - this.keypressTimestamps[0];

    if (currentValue.length > 13) {
      currentValue = currentValue.slice(-13);
    }
    if (
      timediff <= timeThreshold && // Input speed < 50ms
      /^\d{13}$/.test(currentValue) // Check if input is numeric and exactly 13 characters
    ) {
      if (currentValue === this.scannedCode) {
        console.log('timediff:', timediff);

        // Check if at least 2 seconds have passed since the last emission
        if (currentTimestamp - this.lastEmissionTime >= this.EMISSION_INTERVAL) {
          this.handleAmountChanged.emit(1);
          this.lastEmissionTime = currentTimestamp; // Update the last emission time
        }
      }
      this.scannedCode = currentValue;
      this.searchFormGroup.controls.searchTerm.patchValue('');
      this.searchFormGroup.controls.searchTerm.patchValue(this.scannedCode);
    }
  }
  public onResetSearch(event: Event): void {
    event.preventDefault();
    this.searchFormGroup.controls.searchTerm.patchValue('');
  }

  public watchArticleGroup() {
    this.searchFormGroup.controls.articleGroupId.valueChanges.subscribe(res => this.handleArticleGroupChanged.emit(res));
  }
  public watchArticleGroupSearch() {
    this.articleGroups$ = this.searchFormGroup.controls.articleGroupSearchTerm.valueChanges.pipe(
      startWith(''),
      switchMap(res => {
        return this.articleGroupService.getArticleGroups(res!);
      }),
      map(res => {
        return res;
      }),
    );
  }
}
