<table
  mat-table
  matSort
  [matSortActive]="sort.active"
  [matSortDirection]="sort.direction"
  [dataSource]="tableDataSource"
  (matSortChange)="onSortChanged($event)"
  multiTemplateDataRows="true"
>
  <ng-container matColumnDef="edit">
    <mat-header-cell *matHeaderCellDef>
      @if (forLabels) {
        <mat-checkbox [checked]="allChecked" (change)="selectAll()"></mat-checkbox>
      }
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      @if (showCollapse && element.isBaseArticle && element.hasChildItems && expandedRow?.id !== element.id) {
        <button
          [disabled]="!element.isBaseArticle"
          mat-icon-button
          [matTooltipPosition]="'above'"
          matTooltip="Toon vervangartikelen"
          (click)="$event.stopPropagation(); onExpandArticleRow(element)"
        >
          <mat-icon>expand_more</mat-icon>
        </button>
      } @else if (element.isBaseArticle && element.hasChildItems && expandedRow?.id === element.id) {
        <button
          [disabled]="!element.isBaseArticle"
          mat-icon-button
          [matTooltipPosition]="'above'"
          matTooltip="Verberg vervangartikelen"
          (click)="$event.stopPropagation(); onExpandArticleRow(element)"
        >
          <mat-icon>expand_less</mat-icon>
        </button>
      }
      @if (!isReadOnly) {
        <button
          mat-icon-button
          [matTooltipPosition]="'above'"
          matTooltip="Dupliceer artikel"
          (click)="$event.stopPropagation(); onCopyArticle(element.id)"
        >
          <mat-icon color="primary">content_copy</mat-icon>
        </button>
        <button
          mat-icon-button
          [matTooltipPosition]="'above'"
          matTooltip="Artikel bewerken"
          (click)="onClickArticle(element)"
        >
          <mat-icon color="primary">edit</mat-icon>
        </button>
        @if (showUnlink) {
          <button
            mat-icon-button
            [matTooltipPosition]="'above'"
            matTooltip="Vervangartikel verwijderen"
            (click)="$event.stopPropagation(); onUnlinkArticle(element.id)"
          >
            <mat-icon color="warn">link_off</mat-icon>
          </button>
        } @else {
          <button
            [disabled]="!element.allowDelete"
            mat-icon-button
            [matTooltipPosition]="'above'"
            matTooltip="Artikel verwijderen"
            (click)="$event.stopPropagation(); onDeleteArticle(element.id)"
          >
            <mat-icon [class.is-disabled]="element.isBaseArticle" color="warn">delete</mat-icon>
          </button>
        }
      } @else if (forLabels) {
        <mat-checkbox
          [checked]="isChecked(element.id)"
          [value]="element.id"
          (change)="updateSelected(element.id)"
        ></mat-checkbox>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      @if (showCollapse && element.isBaseArticle && element.hasChildItems && expandedRow?.id !== element.id) {
        <button
          [disabled]="!element.isBaseArticle"
          mat-icon-button
          (click)="$event.stopPropagation(); onExpandArticleRow(element)"
        >
          <mat-icon>expand_more</mat-icon>
        </button>
      } @else if (element.isBaseArticle && element.hasChildItems && expandedRow?.id === element.id) {
        <button
          [disabled]="!element.isBaseArticle"
          mat-icon-button
          (click)="$event.stopPropagation(); onExpandArticleRow(element)"
        >
          <mat-icon>expand_less</mat-icon>
        </button>
      }
      <button mat-icon-button (click)="onClickArticle(element)">
        <mat-icon color="primary">check</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="code">
    <mat-header-cell *matHeaderCellDef [ngClass]="isDialog ? 'mat-header-code--dialog' : ''" mat-sort-header
      >Code</mat-header-cell
    >
    <mat-cell *matCellDef="let element" [ngClass]="isDialog ? 'mat-column-code--dialog' : ''">{{
      element.code
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef [ngClass]="isDialog ? 'mat-header-description--dialog' : ''" mat-sort-header
      >Omschrijving</mat-header-cell
    >
    <mat-cell *matCellDef="let element" [ngClass]="isDialog ? 'mat-column-description--dialog' : ''">{{
      element.description
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="articleGroupDescription">
    <mat-header-cell
      *matHeaderCellDef
      [ngClass]="isDialog ? 'mat-header-articleGroupDescription--dialog' : ''"
      mat-sort-header
      >Groep</mat-header-cell
    >
    <mat-cell *matCellDef="let element" [ngClass]="isDialog ? 'mat-column-articleGroupDescription--dialog' : ''">{{
      element.articleGroupDescription
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="exclusiveVatPrice">
    <mat-header-cell
      *matHeaderCellDef
      [ngClass]="{
        'mat-header-exclusiveVatPrice--dialog': isDialog,
        'mat-header-exclusiveVatPrice--purchase': showPurchasePrice(),
      }"
      >Prijs excl.</mat-header-cell
    >
    <mat-cell
      *matCellDef="let element"
      [ngClass]="{
        'mat-column-exclusiveVatPrice--dialog': isDialog,
        'mat-column-exclusiveVatPrice--purchase': showPurchasePrice(),
      }"
      >{{ element.recommendedSalesPrice | currency: 'EUR' : 'symbol' : '0.2' : 'nl' }}</mat-cell
    >
  </ng-container>

  <ng-container matColumnDef="webshopPrice">
    <mat-header-cell
      *matHeaderCellDef
      [ngClass]="{
        'mat-header-webshopPrice--dialog': isDialog,
        'mat-header-webshopPrice--purchase': showPurchasePrice(),
      }"
      >Prijs incl.</mat-header-cell
    >
    <mat-cell
      *matCellDef="let element"
      [ngClass]="{
        'mat-column-webshopPrice--dialog': isDialog,
        'mat-column-webshopPrice--purchase': showPurchasePrice(),
      }"
      >{{ element.privatePrice | currency: 'EUR' : 'symbol' : '0.2' : 'nl' }}</mat-cell
    >
  </ng-container>

  <ng-container matColumnDef="nettoPurchasePrice">
    <mat-header-cell *matHeaderCellDef>Aankoop prijs</mat-header-cell>
    <mat-cell *matCellDef="let element">{{
      element.nettoPurchasePrice | currency: 'EUR' : 'symbol' : '0.2' : 'nl'
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="stockQuantity">
    <mat-header-cell *matHeaderCellDef [ngClass]="isDialog ? 'mat-header-stockQuantity--dialog' : ''"
      >Voorraad</mat-header-cell
    >
    <mat-cell
      *matCellDef="let element"
      [ngClass]="{
        'mat-column-stockQuantity--dialog': isDialog,
        'mat-column-stockQuantity--stock-edit': editStock,
      }"
    >
      @if (editStock) {
        <mat-form-field [subscriptSizing]="'dynamic'">
          <input
            [id]="'input' + element.id"
            matInput
            (keypress)="onEnterPressed($event, element)"
            (blur)="updateStockQuantity(element)"
            type="number"
            [(ngModel)]="element.stockQuantity"
            [disabled]="!element.trackTransactions"
            (focus)="onFocusField($event)"
          />
        </mat-form-field>
      } @else {
        {{ element.stockQuantity }}
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="orderedQuantity">
    <mat-header-cell *matHeaderCellDef [ngClass]="isDialog ? 'mat-header-orderedQuantity--dialog' : ''"
      >Besteld</mat-header-cell
    >
    <mat-cell *matCellDef="let element" [ngClass]="isDialog ? 'mat-column-orderedQuantity--dialog' : ''">{{
      element.orderedQuantity
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="location">
    <mat-header-cell *matHeaderCellDef>Locatie</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.location }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="backOrderQuantity">
    <mat-header-cell *matHeaderCellDef [ngClass]="isDialog ? 'mat-header-backOrderQuantity--dialog' : ''"
      >B.O</mat-header-cell
    >
    <mat-cell *matCellDef="let element" [ngClass]="isDialog ? 'mat-column-backOrderQuantity--dialog' : ''">{{
      element.backOrderQuantity
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="isBaseArticle">
    <mat-header-cell [ngClass]="isDialog ? 'mat-header-isBaseArticle--dialog' : ''" *matHeaderCellDef
      >Hoofdartikel</mat-header-cell
    >
    <mat-cell *matCellDef="let element" [ngClass]="isDialog ? 'mat-column-isBaseArticle--dialog' : ''">
      {{ element.isBaseArticle ? 'Ja' : 'Nee' }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="supplierReference">
    <mat-header-cell [ngClass]="isDialog ? 'mat-header-supplierReference--dialog' : ''" *matHeaderCellDef
      >Referentie</mat-header-cell
    >
    <mat-cell *matCellDef="let element" [ngClass]="isDialog ? 'mat-column-supplierReference--dialog' : ''">
      {{ element.supplierReference }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expandedRow">
    <mat-cell *matCellDef="let element" class="articles__inner" [attr.colspan]="displayedColumns.length">
      @if (element.id === (expandedRow?.id ?? 0)) {
        <ingenix-article-table
          [isDialog]="isDialog"
          [editStock]="editStock"
          [forStock]="forStock"
          (rowClicked)="onClickArticle($event)"
          (expandArticleRow)="onExpandArticleRow($event)"
          (deleteClicked)="onDeleteArticle($event)"
          [articles]="replacementArticles"
          [replacementArticles]="[]"
          [selectedArticleId]="selectedArticleId"
          [displayedColumns]="displayedColumns"
          [showHeader]="false"
        >
        </ingenix-article-table>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="progress">
    <th mat-cell *matCellDef="let element"></th>
    <td class="loader" mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumns.length">
      @if (isLoading()) {
        <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
      }
    </td>
  </ng-container>

  @if (showHeader) {
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  }
  <tr mat-header-row *matHeaderRowDef="['progress']; sticky: true" class="progress-row"></tr>
  <mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{ isSelected: row.id === selectedArticleId }"
    (click)="onClickArticle(row)"
  ></mat-row>
  <mat-row
    *matRowDef="let row; columns: ['expandedRow']"
    [ngClass]="{ isHidden: row.id !== (expandedRow?.id ?? 0) }"
  ></mat-row>
</table>
@if (tableDataSource.data.length === 0 || !tableDataSource.data) {
  <div class="no-content">Geen artikels met lus.</div>
}
@if (showHeader) {
  <mat-paginator
    #paginator
    [length]="totalCount"
    [pageSizeOptions]="[5, 10, 20, 25, 50, 100]"
    [pageSize]="paginator?.pageSize ?? 10"
  ></mat-paginator>
}
