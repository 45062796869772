<div class="article-filter">
  <form
    class="article-filter__form"
    [ngClass]="{
      'article-filter__form--single': !showMainArticleFilter && !showPurchasePriceFilter,
      'article-filter__form--multiple': showMainArticleFilter && showPurchasePriceFilter,
    }"
    [formGroup]="searchFormGroup"
  >
    <div class="article-filter__search">
      <mat-form-field [appearance]="'outline'" [subscriptSizing]="'dynamic'" class="article-filter__input">
        <mat-label>Zoek een artikel</mat-label>
        <input
          matInput
          formControlName="searchTerm"
          type="text"
          autocomplete="off"
          (keydown.enter)="$event.preventDefault()"
          (keyup)="onKeyPress()"
        />
        <mat-icon matPrefix>search</mat-icon>
        <button (click)="onResetSearch($event)" matSuffix mat-icon-button class="-no-background">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    @if (showArticleGroupFilter) {
      <ingenix-list-item
        [valueFormControl]="searchFormGroup.controls.articleGroupId"
        [searchTermFormControl]="searchFormGroup.controls.articleGroupSearchTerm"
        [label]="'Artikelgroep'"
        [listItems]="(articleGroups$ | async) ?? []"
      ></ingenix-list-item>
    }
    @if (showMainArticleFilter) {
      <div
        class="article-filter__options"
        [ngClass]="{
          'article-filter__options--multiple': showMainArticleFilter && showPurchasePriceFilter,
        }"
      >
        @if (showMainArticleFilter) {
          <mat-checkbox class="article-filter__option" formControlName="isBaseArticle" color="primary"
            >Toon enkel hoofdartikelen
          </mat-checkbox>
        }

        @if (showPurchasePriceFilter) {
          <mat-checkbox class="article-filter__option" formControlName="showPurchasePrice" color="primary"
            >Toon aankoopprijs
          </mat-checkbox>
        }
      </div>
    }
  </form>
</div>
